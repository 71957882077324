import React from 'react';
import { switchChain} from "../../utils/wallet.js";

import Web3Modal from "web3modal";
import WalletLink from "walletlink";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Paper from '@mui/material/Paper';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import styles from "./inscription.module.css";

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import {
  MAX_AMOUNT
} from "../../utils/configuration";



const ctx = require.context("../../assets/slider-imgs", true, /.png$/);
let images = [];
ctx.keys().forEach((key) => {
  images.push(ctx(key));
});


const providerOptions = {
  walletlink: {
    package: WalletLink, // Required
    options: {
      appName: "CorePig", // Required
      infuraId: "INFURA_ID", // Required unless you provide a JSON RPC url; see `rpc` below
      rpc: "", // Optional if `infuraId` is provided; otherwise it's required
      chainId: 1, // Optional. It defaults to 1 if not provided
      appLogoUrl: null, // Optional. Application logo image URL. favicon is used if unspecified
      darkMode: false, // Optional. Use dark theme, defaults to false
    },
  },
  walletconnect: {
    display: {
      name: "Mobile",
    },
    package: WalletConnectProvider,
    options: {
      infuraId: "INFURA_ID", // required
    },
  },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const INSCRIPTION = ({ data }) => {

  data.web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
    providerOptions: providerOptions,
  });

    return (

      <div className="mint-logo-container mint-instruction ml-[-1.5vw]">
  <div className={styles.mint_bg1}>
  <div className={styles.ranking_container}>
    <table className={styles.ranking_table}>
      <thead className={styles.ranking_table_header_left}>
        <tr>
          <th >Name</th>
          <th >Deploy Time</th>
          <th >Process</th>
          <th >Minted</th>
          <th >Total Supply</th>
          <th >Your Balance</th>
        </tr>
        </thead>

        <tbody>
        <tr className={styles.divider}>
      <td colSpan="6"></td>
    </tr>
    <tr className={styles.ranking_table_header_left}>
      <td>AIs</td>
      <td>2023/03/22 20:00:00</td>
      <td>{data.state.percentage}</td>
      <td>{data.state.mintAmount}</td>
      <td>{MAX_AMOUNT}0</td>
      <td>{data.state.balance}</td>
    </tr>
  </tbody>
        </table>
  </div>
    </div>
    <div className={styles.mint_bg1_mobile}>
    <List className={styles.list_style} component="nav" >
    <List className={styles.list_style} component="nav" >
      <div className={styles.list_item}>
      <div>
      <div className={styles.reward_font}  >OVERVIEW </div>
      </div>
      </div>
    </List>
    <div  className={styles.listItem_style}>
        <span className={styles.reward_font} >Name</span> 
        <span className={styles.reward_font} >AIs</span> 
      </div>
      <Divider className={styles.divider}  />
      <div  className={styles.listItem_style}>
        <span className={styles.reward_font} >Deploy Time</span> 
        <span className={styles.reward_font} >2023/03/22 20:00:00</span> 
      </div>
      <Divider className={styles.divider}  />

      <div  className={styles.listItem_style}>
        <span className={styles.reward_font} >Process</span> 
        <span className={styles.reward_font} >{data.state.percentage}</span> 
      </div>
      <Divider className={styles.divider}  />

      <div  className={styles.listItem_style}>
        <span className={styles.reward_font} >Minted</span> 
        <span className={styles.reward_font} >{data.state.mintAmount}</span> 
      </div>
      <Divider className={styles.divider}  />

      <div  className={styles.listItem_style}>
        <span className={styles.reward_font} >Total Supply</span> 
        <span className={styles.reward_font} >{MAX_AMOUNT}0</span> 
      </div>
      <Divider className={styles.divider}  />

      <div  className={styles.listItem_style}>
        <span className={styles.reward_font} >Your Balance</span> 
        <span className={styles.reward_font} >{data.state.balance}</span> 
      </div>
      <Divider className={styles.divider}  />

    </List>
    </div>
  </div>
);
};


